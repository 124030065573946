import React, {useState} from "react";

import {useRecoilState, useResetRecoilState} from "recoil";
import registerFormState from "./atoms";
import {presentationError} from "../../../errors/presentationError";
import Input from "./form/Input";
import LoaderButton from "../../common/button/LoaderButton";
import {Col, Row} from "react-bootstrap";
import {register} from "../../../repository/AuthRepository";
import {notificationAlert} from "../../../util/alert";
import Select from "../../myAccount/form/Select";


const RegisterPage: React.FC = () => {

    const [formState, setFormState] = useRecoilState(registerFormState)

    const resetForm = useResetRecoilState(registerFormState);
    const [loader, setLoader] = useState<boolean>(false);
    const handleRegister = async () => {
        try {
            if (formState.password !== formState.passwordConfirm) {
                notificationAlert('notification', 'wrong password confirm');
                return;
            }
            setLoader(true);
            const result = await register({
                email: formState.email,
                password: formState.password,
                username: formState.username,
                firstname: formState.firstname,
                lastname: formState.lastname,
                postalCode: formState.postalCode,
                country: formState.country,
                mobile_code: formState.mobile_code,
                mobile_number: formState.mobile_number,
            });

            if (result.success) {
                resetForm();
                setLoader(false);
                notificationAlert('알림', 'Complete Register');
                window.location.href = '/';
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '회원가입',
                alert: true
            });
            setLoader(false);
        }
    }


    return (
        <div className={'register_page_wrapper'}>
            <div className={'register_box'}>
                <div style={{padding: '1rem 0'}}>
                    <h4>Register</h4>
                </div>

                <Input type={'text'} label={'email'} name={'email'} placeholder={'email'}/>
                <Input type={'password'} label={'비밀번호'} name={'password'} placeholder={'password'}/>
                <Input type={'password'} label={'비밀번호 확인'} name={'passwordConfirm'} placeholder={'password confirm'}/>
                <Input type={'text'} label={'username'} name={'username'} placeholder={'username'}/>
                <Input type={'text'} label={'firstname'} name={'firstname'} placeholder={'firstname'}/>
                <Input type={'text'} label={'lastname'} name={'lastname'} placeholder={'lastname'}/>
                <Input type={'number'} label={'postalCode'} name={'postalCode'} placeholder={'postalCode'}/>
                <Input type={'text'} label={'country'} name={'country'} placeholder={'country'}/>
                <Row className={'w-100'}>
                    <Col xs={2} className={'p-0'}>
                        <Select name={'mobile_code'} options={[
                            {value: '1', txt: 'United States +1'},
                            {value: '1', txt: 'Canada +1'},
                            {value: '52', txt: 'Mexico +52'},
                            {value: '82', txt: 'Korea +82'},
                        ]}/>
                    </Col>
                    <Col xs={10} className={'p-0'}>
                        <Input type={'text'} label={'mobile_number'} name={'mobile_number'}
                               placeholder={'mobile_number'}/>
                    </Col>
                </Row>
                <div className={'register_sub_btn'}>
                    <LoaderButton onClick={handleRegister} loader={loader}>
                        Register
                    </LoaderButton>
                </div>
            </div>
        </div>
    )
}


export default RegisterPage;