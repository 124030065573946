import {atom} from 'recoil'
import {RegisterRequest} from "../../../model/request/RegisterRequest";




interface IRegisterForm {
    email: string
    password: string
    passwordConfirm: string
    username: string
    firstname: string
    lastname: string
    postalCode: string
    country: string
    mobile_code: string
    mobile_number: string

}

const registerFormState = atom<IRegisterForm>({
    key: 'registerFormState',
    default: {
        email: '',
        password: '',
        passwordConfirm: '',
        username: '',
        firstname: '',
        lastname: '',
        postalCode: '',
        country: '',
        mobile_code: '',
        mobile_number: '',
    }
})


export default registerFormState;