import React, {useState} from "react"
import {RecoilRoot} from 'recoil'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {RouteIf} from "./util/RouteIf";
import {LoadScript} from "@react-google-maps/api";
import {MapLoader} from "./component/main/MapLoader";
import Dashboard from "./component/main/Dashboard";
import MainLayout from "./component/layout/MainLayout";
import DriverReportPage from "./component/driverReport/DriverReportPage";
import MyAccountDetailPage from "./component/myAccount/MyAccountDetailPage";
import ManageCardPage from "component/myAccount/manageCard/ManageCardPage";
import ManageVehiclePage from "./component/myAccount/manageVehicles/ManageVehiclePage";
import AccountStatementPage from "./component/myAccount/statement/AccountStatementPage";
import {isLogin} from "./util/auth";
import LoginPage from "./component/auth/login/LoginPage";
import RegisterPage from "./component/auth/register/RegisterPage";

type Libraries = ("drawing" | "geometry" | "localContext" | "places" | "visualization")[];

const Router: React.FC = () => {

    const [mapLibrary] = useState<Libraries>(['places', 'geometry', 'drawing']);

    return (
        <RecoilRoot>
            <BrowserRouter>
                <LoadScript
                    loadingElement={<MapLoader/>}
                    id="script-loader"
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY ?? ""}
                    libraries={mapLibrary}
                    language="ko"
                    region="us"
                >
                    <Routes>
                        <Route path={"/"} element={
                            <RouteIf option={isLogin()}>
                                {isLogin() ?
                                    <MainLayout>
                                        <Dashboard/>
                                    </MainLayout>
                                    :
                                    <LoginPage/>
                                }
                            </RouteIf>}/>
                        <Route path={"register"} element={
                            <RouteIf option={false}>
                                    <RegisterPage/>
                            </RouteIf>}/>
                        <Route path={"report"} element={
                            <RouteIf option={true}>
                                <MainLayout>
                                    <DriverReportPage/>
                                </MainLayout>
                            </RouteIf>}/>
                        <Route path={"my"}>
                            <Route path={"detail"} element={
                                <RouteIf option={true}>
                                    <MainLayout>
                                        <MyAccountDetailPage/>
                                    </MainLayout>
                                </RouteIf>}/>
                            <Route path={"cards"} element={
                                <RouteIf option={true}>
                                    <MainLayout>
                                        <ManageCardPage/>
                                    </MainLayout>
                                </RouteIf>}/>
                            {/*<Route path={"vehicles"} element={*/}
                            {/*    <RouteIf option={true}>*/}
                            {/*        <MainLayout>*/}
                            {/*            <ManageVehiclePage/>*/}
                            {/*        </MainLayout>*/}
                            {/*    </RouteIf>}/>*/}
                            <Route path={"statement"} element={
                                <RouteIf option={true}>
                                    <MainLayout>
                                        <AccountStatementPage/>
                                    </MainLayout>
                                </RouteIf>}/>
                        </Route>
                    </Routes>
                </LoadScript>
            </BrowserRouter>
        </RecoilRoot>
    )
}

export default Router
