import React from 'react';
import {useRecoilState} from 'recoil';
import {InputBase} from "../../../common";
import registerFormState from "../atoms";

type Props = {
    type: string
    name: string
    placeholder: string
    label: string
};

const Input: React.FC<Props> = ({type, name, placeholder, label}: Props) => {
    const [state, setState] = useRecoilState(registerFormState);

    return (
        <div className={'register_input_wrapper create_input_wrapper'}>
            <InputBase
                type={type}
                name={name}
                placeholder={placeholder}
                state={state}
                setState={setState}
            />
        </div>
    );
};

export default Input;
