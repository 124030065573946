import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from "./Router";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/index.scss'


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<Router/>);


