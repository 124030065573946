import {useRecoilState} from 'recoil'
import React from 'react'
import {createCardFormState, createVehicleFormState, userProfileFormState} from "../atoms";
import {InputBase} from "../../common";

type Props = {
    type: string
    name: string
    placeholder: string
    disabled?: boolean
    value?: string
}

const PcInput: React.FC<Props> = ({type, name, placeholder, disabled = false, value}: Props) => {
    const [state, setState] = useRecoilState(createCardFormState)


    return (
        <InputBase
            className={'base_input'}
            disabled={disabled}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            state={state}
            setState={setState}
        />
    )
}

export default PcInput
