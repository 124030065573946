import React, {useCallback, useEffect, useRef, useState} from "react"
import {GoogleMap} from '@react-google-maps/api';
import LeftSideBar from "../sidebar/LeftSideBar";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {mapState} from "./atoms";
import {loaderState} from "../common/loader/atoms";
import CircularProgress from '@mui/material/CircularProgress';
import {sidebarState, stationsState} from "../sidebar/atoms";
import SearchChargeNear from "./SearchChargeNear";
import RightSideBar from "../sidebar/RightSideBar";
import {Marker} from '@react-google-maps/api';
import UserProfileBar from "./UserProfileBar";
import ChargingStats from "./ChargingStats";
import AccountActivityTable from "./AccountActivityTable";
import {presentationError} from "../../errors/presentationError";
import {getStationList} from "../../repository/StationRepository";
import pinIcon from "../../assets/icon/pin.svg";
import {Station} from "../../model/response/StationListResponse";
import StationInfoDialog from "./tab/StationInfoDialog";
import {userInfoState} from "../nav/atoms";
import {isIterableArray} from "../../util/common";

const Dashboard: React.FC = () => {
    const [map, setMap] = useState<google.maps.Map>()
    const state = useRecoilValue(mapState);
    const sidebar = useRecoilValue(sidebarState);
    const loader = useRecoilValue(loaderState);
    const [stations, setStations] = useRecoilState(stationsState);
    const setUserInfo = useSetRecoilState(userInfoState);
    const getStations = async () => {
        try {

            const locationPermission = await navigator.permissions.query({name: 'geolocation'});
            if (locationPermission.state === "denied") {
                const result = await getStationList({
                    status: sidebar.stationStatus,
                    type: sidebar.stationConnType,
                    lat: 0,
                    lng: 0
                })

                if (result.success && result.data) {
                    setStations(result.data);
                    setUserInfo(old => ({...old, step2: false, step5: true}));
                }
            } else {
                navigator.geolocation.getCurrentPosition(async function (pos) {
                    const latitude = pos.coords.latitude;
                    const longitude = pos.coords.longitude;
                    const result = await getStationList({
                        status: sidebar.stationStatus,
                        type: sidebar.stationConnType,
                        lat: latitude,
                        lng: longitude
                    })

                    if (result.success && result.data) {
                        setStations(result.data);
                        setUserInfo(old => ({...old, step2: true, step5: true}));
                    }

                });
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'get stations',
                alert: true
            });
            setUserInfo(old => ({...old, step2: false, step5: false}));
        }
    }

    useEffect(() => {
        getStations();
    }, [sidebar.stationStatus, sidebar.stationConnType])

    useEffect(() => {
        if (map) {
            map.setCenter(state.center)
            map.setZoom(state.zoom)
        }
    }, [state.center, state.zoom])

    const center = {
        lat: 37.575987740229,
        lng: 126.97677991349349
    };

    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        map.setZoom(9)
        setMap(map)
    }, [])


    const handleClickMarker = (station: Station) => {
        const recentStations = localStorage.getItem('recentStation');
        if (recentStations) {
            const parseStations = JSON.parse(recentStations);
            if (isIterableArray(parseStations)) {
                const alreadyIndex = parseStations.findIndex((st: Station) => st.cs_no === station.cs_no);
                if (alreadyIndex === -1) {
                    const newRecent = [station, ...parseStations];
                    localStorage.setItem('recentStation', JSON.stringify(newRecent));
                }
            }
        } else {
            localStorage.setItem('recentStation', JSON.stringify([station]));
        }
    }


    return (
        <div className={'dashboard_wrapper'}>
            <SearchChargeNear/>
            <div className={'map_console_main'}>
                <div className={'wrap_border'}>
                    {loader.screenLoad &&
                        <div className={'screen_loader_main'}>
                            <div className={'loader_box'}>
                                <CircularProgress/>
                            </div>
                        </div>}
                    <LeftSideBar/>
                    <RightSideBar/>
                    <GoogleMap
                        onLoad={onLoad}
                        options={
                            {
                                zoom: state.zoom,
                                minZoom: 1,
                                maxZoom: 22,
                                mapTypeId: 'roadmap',
                                mapTypeControl: true,
                                fullscreenControl: false,
                                streetViewControl: false,
                                zoomControl: true,
                            }
                        }
                        mapContainerStyle={{
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        {
                            stations.map(station => <Marker
                                key={station.cs_address}
                                icon={{
                                    url: pinIcon,
                                }}
                                onClick={() => handleClickMarker(station)}
                                position={new google.maps.LatLng({
                                    lat: Number(station.cs_lat),
                                    lng: Number(station.cs_lng)
                                })}/>)
                        }
                    </GoogleMap>
                </div>
                <div className={'desc_bar'}>
                    EVpoint App Now Supports Tap to Charge. Find out more <a href={'https://evmode.com/en'}
                                                                             target={'_blank'}>here.</a>
                </div>

                <UserProfileBar/>
                <ChargingStats/>
                <AccountActivityTable/>
                <StationInfoDialog/>
            </div>
        </div>
    )
}


export default Dashboard
