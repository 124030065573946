import {UserResponse} from "../model/response/UserResponse";
import {AuthorizeHttpClient} from "../api/authorize-http-client-decorator";
import {API_ADDRESS} from "../api/ApiFactory";
import {HttpStatusCode} from "../api/http-client";
import {ApiError} from "../model/errors/ApiError";
import {IBaseResponse} from "../model/response/base-response-model";
import {UnexpectedError} from "../model/errors/unexpected-error";
import {UpdateUserRequest} from "../model/request/UpdateUserRequest";
import {CreateVehicleRequest} from "../model/request/CreateVehicleRequest";
import {VehicleListResponse, VehicleResponse} from "../model/response/VehicleResponse";
import {UpdateVehicleRequest} from "../model/request/UpdateVehicleRequest";
import {BasicResponse} from "../model/response/BasicResponse";
import {ChargeResponse} from "../model/response/ChargeResponse";
import {UserNameResponse} from "../model/response/UserNameResponse";

export const getUser = async (): Promise<UserResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/user',
        method: 'get'
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as UserResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}

export const getChargeData = async (): Promise<ChargeResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/user/charge',
        method: 'get'
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as ChargeResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}

export const updateUser = async (request: UpdateUserRequest): Promise<UserResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/user',
        method: 'put',
        body: request
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as UserResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}



export const createUserVehicle = async (request: CreateVehicleRequest): Promise<VehicleResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/user/vehicle',
        method: 'post',
        body: request
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.created: return httpResponse.body as VehicleResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}

export const getUserVehicle = async (): Promise<VehicleListResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/user/vehicle',
        method: 'get'
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as VehicleListResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}

export const updateUserVehicle = async (request: UpdateVehicleRequest): Promise<VehicleResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/user/vehicle/' + request.vehicleNo,
        method: 'patch',
        body: request.dto
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as VehicleResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}


export const deleteUserVehicle = async (vehicleNo: number): Promise<BasicResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/user/vehicle/' + vehicleNo,
        method: 'delete'
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as BasicResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}

export const getUserName = async (): Promise<UserNameResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/user/name',
        method: 'get'
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as UserNameResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}
