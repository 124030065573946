import React, {useState} from "react";
import pencilIcon from "../../assets/icon/pencil.svg";
import checkIcon from "../../assets/icon/check.svg";
import closeIcon from "../../assets/icon/close.svg";
import Input from "./form/Input";
import {presentationError} from "../../errors/presentationError";
import {useRecoilState, useRecoilValue} from "recoil";
import {myAccountDetailState, userProfileFormState} from "./atoms";
import Select from "./form/Select";
import {updateUser} from "../../repository/UserRepository";
import ModifyCreditCardDialog from "./ModifyCreditCardDialog";

interface Info {
    name: string
    value: string
    formName?: string
}

interface IModifyFormComp {
    mode: boolean
    info: Info
}

const ModifyFormComp: React.FC<IModifyFormComp> = ({mode, info}) => {
    const formState = useRecoilValue(userProfileFormState);
    switch (info.name) {
        case 'Password' :
            return (
                <>
                    {mode ?
                        <>
                            <div className={'info'}>
                                <Input name={'password'} type={'text'} placeholder={''}/>
                                <small>Current Password</small>
                            </div>
                            <div className={'info'}>
                                <div className={'mb_sm'}>
                                    <Input name={'newPassword'} type={'text'} placeholder={''}/>
                                    <small>New Password</small>
                                </div>
                                <div className={'mb_sm'}>
                                    <Input name={'newPassword'} type={'text'} placeholder={''}/>
                                    <small>Verify Password</small>
                                </div>
                            </div>
                        </>
                        :
                        <div className={'info'}>
                            <p className={'info_value'}>{info.value}</p>
                            <small>{info.name}</small>
                        </div>
                    }
                </>
            )
        case 'Preferred Language':
            return (
                <>
                    {mode ?
                        <div className={'info'}>
                            <div className={'account_profile_form'}>
                                <Select name={'language'} options={[
                                    {value: 'Korean', txt: 'Korean'},
                                    {value: 'English', txt: 'English'},
                                    {value: 'Español', txt: 'Español'},
                                    {value: 'Français', txt: 'Français'},
                                ]}/>
                            </div>
                        </div>
                        :
                        <div className={'info'}>
                            <p className={'info_value'}>{info.value}</p>
                            <small>{info.name}</small>
                        </div>
                    }
                </>
            )
        case 'Mobile':
            return (
                <>
                    {mode ?
                        <div className={'info'}>
                            <div className={'d-flex account_profile_form mobile_phone_form'}>
                                <Select name={'mobile_code'} options={[
                                    {value: '1', txt: 'United States +1'},
                                    {value: '1', txt: 'Canada +1'},
                                    {value: '52', txt: 'Mexico +52'},
                                    {value: '82', txt: 'Korea +82'},
                                ]}/>
                                <Input value={formState.mobile_number} name={'mobile_number'} type={'text'}
                                       placeholder={''}/>
                            </div>
                        </div>
                        :
                        <div className={'info'}>
                            <p className={'info_value'}>{info.value}</p>
                            <small>{info.name}</small>
                        </div>
                    }
                </>
            )

        default:
            return (
                <div className={'info'}>
                    <>
                        {(mode && info.formName) ?
                            <Input value={formState[info.formName]} name={info.formName} type={'text'}
                                   placeholder={''}/>
                            :
                            <p className={'info_value'}>{info.value}</p>
                        }

                    </>
                    <small>{info.name}</small>
                </div>
            )
    }

}

interface IProps {
    title: string
    infos: Info[]
}

const InfoSection: React.FC<IProps> = ({title, infos}) => {
    const [modifyMode, setModifyMode] = useState<boolean>(false);
    const userProfileForm = useRecoilValue(userProfileFormState);
    const [userProfileDetail, setUserProfileDetail] = useRecoilState(myAccountDetailState);

    const modifyProfile = async () => {
        try {
            const result = await updateUser(userProfileForm);

            if (result.success) {
                setModifyMode(false);
                setUserProfileDetail(Object.assign({}, userProfileDetail, userProfileForm));
            }
        } catch (err) {
            presentationError({
                error: err,
                type: 'modify user profile',
                alert: true
            });
        }
    }

    return (
        <div className={'info_section_wrapper'}>
            <div className={'section_header'}>
                <h5>{title}</h5>
                {modifyMode ?
                    <div className={'d-flex'}>
                        <button onClick={() => setModifyMode(false)}>
                            <img src={closeIcon} alt={'modify_icon'}/>
                        </button>
                        <button onClick={() => modifyProfile()}>
                            <img src={checkIcon} alt={'modify_icon'}/>
                        </button>
                    </div>
                    :
                    <>
                        {title === 'Financial Information' ?
                            <ModifyCreditCardDialog/>
                            :
                            <button onClick={() => setModifyMode(true)}>
                                <img src={pencilIcon} alt={'modify_icon'}/>
                            </button>
                        }
                    </>
                }

            </div>
            <div className={'content'}>
                {infos.map(info => <ModifyFormComp key={info.name} mode={modifyMode} info={info}/>)}
            </div>

        </div>
    )
}


export default InfoSection;