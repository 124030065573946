import React, {useEffect, useState} from "react";
import greenPlaceIcon from '../../../assets/icon/green_place.svg';
import grayPlaceIcon from '../../../assets/icon/gray_place.svg';
import navigationIcon from '../../../assets/icon/navigation.svg';
import {useSetRecoilState} from "recoil";
import {getConnTypeString} from "../../../util/common";
import {Station} from "../../../model/response/StationListResponse";
import {stationDialogState} from "../atoms";


const RecentSpotsTab: React.FC = () => {

    const [stations, setStations] = useState<Station[]>([]);
    const setStationDialog = useSetRecoilState(stationDialogState);
    useEffect(() => {
        const stroageStations = localStorage.getItem('recentStation');
        if (stroageStations){
            setStations(JSON.parse(stroageStations));
        }
    },[])


    const handleClickSpot = (station: Station) => {
        setStationDialog({
            isOpen: true,
            station: station,
            tips: []
        })
    }

    return (
        <div className={'charge_spots_tab_wrapper'}>
            <ul className={'spot_list'}>
                {stations.map(station => <li onClick={() => handleClickSpot(station)} key={station.cs_address}>
                    <div className={'spot_level'}>
                        <img src={station.cs_status === 0 ? grayPlaceIcon : greenPlaceIcon} alt={'place_icon'}/>
                        <small><strong>{getConnTypeString(station.cs_conn_type)}</strong></small>
                    </div>
                    <div className={'spot_info'}>
                        <h4>{station.cs_address}</h4>
                        <p>{`${station.cs_address_city}, ${station.cs_address_state}`}</p>
                        <small>
                            <strong>
                                Estimated Fee:
                            </strong>
                            {` $ ${station.cs_price}`}
                        </small>
                    </div>
                    <button className={'go_nav_btn'}
                            onClick={() => window.open(`https://www.google.co.kr/maps/search/${station.cs_address}`, '_blank')}>
                        <img src={navigationIcon} alt={'navi_btn'}/>
                    </button>
                </li>)}

            </ul>
        </div>
    )
}


export default RecentSpotsTab;
