import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Select from "./form/Select";
import {Col, Row} from "react-bootstrap";
import {stateOptions} from "./constant";
import {presentationError} from "../../../errors/presentationError";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {chargePointCardState, createPointCardFormState} from "../atoms";
import {requestPointCard} from "../../../repository/PaymentRepository";
import Input from "./form/Input";


const CreateCardDialog: React.FC = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [createPointCardForm, setCreatePointCardForm] = useRecoilState(createPointCardFormState);
    const setChargePointCard = useSetRecoilState(chargePointCardState);

    const createPointCard = async () => {
        try {
            const result = await requestPointCard(createPointCardForm);

            if (result.success && result.data) {
                setChargePointCard(result.data);
                handleClose();
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'create point card',
                alert: true
            });
        }
    }

    const handleCntUp = () => {
        setCreatePointCardForm(old => ({...old, cnt: old.cnt + 1}))
    }

    const handleCntDown = () => {
        if (createPointCardForm.cnt > 1) {
            setCreatePointCardForm(old => ({...old, cnt: old.cnt - 1}))
        }

    }

    return (
        <div className={'dialog_wrapper'}>
            <button onClick={handleShow} className={'active_btn'}>Request</button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Request Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'request_card_dialog'}>
                        <p>
                            Your first card is free. Any additional cards are $4.95 each (shipping included) and will be
                            charged to your payment on file. Cards will arrive in approximately 3 to 5 business days.
                        </p>
                        <div className={'form_section'}>
                            <label>How many cards are you ordering?</label>
                            <div className={'card_num_select'}>
                                <button onClick={handleCntDown}>
                                    <svg fill="#ffffff"
                                         xmlns="http://www.w3.org/2000/svg" version="1.1"
                                         preserveAspectRatio="xMidYMid meet" viewBox="4 4 16 16"><title>Remove one
                                        card from the order</title>
                                        <path d="M19,13H5V11H19V13Z"></path>
                                    </svg>
                                </button>
                                <div className={'card_num_txt'}>
                                    {createPointCardForm.cnt} Card
                                </div>
                                <button onClick={handleCntUp}>
                                    <svg fill="#ffffff"
                                         xmlns="http://www.w3.org/2000/svg" version="1.1"
                                         preserveAspectRatio="xMidYMid meet" viewBox="4 4 16 16"><title>Add one more
                                        card</title>
                                        <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className={'form_section'}>
                            <label>Shipping Address</label>

                            <Select name={'shipping_address_country'}
                                    options={[{value: 'United States', txt: 'United States'}]}
                                    label={'Country *'}/>

                            <div className={'form_group'}>
                                <label>Address (Line 1)*</label>
                                <Input type={'text'} name={'shipping_address_line_1'} placeholder={''}/>
                            </div>
                            <div className={'form_group'}>
                                <label>Address (Line 2)*</label>
                                <Input type={'text'} name={'shipping_address_line_2'} placeholder={''}/>
                            </div>
                            <div className={'form_group'}>
                                <label>City*</label>
                                <Input type={'text'} name={'shipping_address_city'} placeholder={''}/>
                            </div>
                            <Row>
                                <Col xs={6}>
                                    <Select name={'shipping_address_state'} options={stateOptions}
                                            label={'State *'}/>
                                </Col>
                                <Col xs={6}>
                                    <div className={'form_group'}>
                                        <label>ZIP Code*</label>
                                        <Input type={'text'} name={'shipping_address_zip_code'} placeholder={''}/>
                                    </div>
                                </Col>
                            </Row>
                            <div className={'total_price'}>
                                Total: ${Math.floor(createPointCardForm.cnt * 4.95)}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose}>
                        Cancel
                    </button>
                    <button onClick={createPointCard}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CreateCardDialog;