import React, {useEffect, useState} from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import singleCarIcon from '../../assets/icon/singleCar.svg';
import multiCarIcon from '../../assets/icon/multiCar.svg';
import classNames from "classnames";
import {presentationError} from "errors/presentationError";
import {getChargeData} from "../../repository/UserRepository";
import {useRecoilState} from "recoil";
import {chargeStatsState} from "./atoms";
import {getMonthName, isIterableArray} from "../../util/common";

const ChargingStats: React.FC = () => {
    const [statsType, setStatType] = useState<'my' | 'total'>('my');
    const [stats, setStats] = useRecoilState(chargeStatsState);
    const [categories, setCategories] = useState<string[]>([]);
    const [seriesData, setSeriesData] = useState<number[]>([]);
    useEffect(() => {
        getChargeStats();
    }, [])


    const getChargeStats = async () => {
        try {
            const result = await getChargeData();

            if (result.success && result.data) {
                setStats(result.data);
                if (isIterableArray(result.data.monthly)) {
                    setCategories(result.data.monthly.map(mo => getMonthName(mo.month ?? 0)));
                    setSeriesData(result.data.monthly.map(mo => mo.sumEnergy));
                }
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'get charge stats',
                alert: true
            });
        }
    }

    return (
        <div className={'charging_stats_section'}>
            <h3 className={'section_title'}>
                Charging Stats - <span>Our Community Stats</span>
            </h3>
            <div className={'stats_wrapper'}>
                <div className={'stats_chart'}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            title: {
                                text: ''
                            },
                            chart: {
                                type: 'column'
                            },
                            tooltip: {
                                pointFormat: '<b>{point.y:.1f} KWh</b>'
                            },
                            legend: {
                                enabled: false
                            },
                            xAxis: {
                                categories: categories
                            },
                            yAxis: {
                                title: {
                                    text: ''
                                },
                                labels: {
                                    formatter: (e: any) => `${Number(e.value).toLocaleString()} KWh`
                                }
                            },
                            plotOptions: {
                                column: {
                                    colorByPoint: true
                                }
                            },
                            colors: ['#CEDEE7', '#CEDEE7', '#CEDEE7', '#CEDEE7', '#CEDEE7', '#BC2555'],
                            series: [{
                                name: 'saveGasoline',
                                data: seriesData,
                            }]
                        }
                        }
                    />
                </div>
                <div className={'stats_tab_wrapper'}>
                    <div className={'tab_btns'}>
                        <button
                            className={classNames({
                                'active': statsType === 'my'
                            })}
                            onClick={() => setStatType('my')}>
                            <img src={singleCarIcon} alt={'car_icon'}/>
                        </button>
                        <button
                            className={classNames({
                                'active': statsType === 'total'
                            })}
                            onClick={() => setStatType('total')}>
                            <img src={multiCarIcon} alt={'car_icon'}/>
                        </button>
                    </div>
                    <div className={'use_energy_info'}>
                        <div className={'energy'}>
                            <h1>{stats.total.sumEnergy}<span>(GWh)</span></h1>
                            <p>Total Energy</p>
                        </div>
                        <div className={'total_charge_ups'}>
                            <h1>{stats.total.sumFee}</h1>
                            <p>Total Fee</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ChargingStats;
