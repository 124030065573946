import React from 'react';
import {useRecoilState} from 'recoil';
import {createVehicleFormState, userProfileFormState} from "../atoms";
import SelectBase, {ISelectItem} from "../../common/select/SelectBase";


type Props = {
    name: string
    options: ISelectItem[]
    valueLabel?: string
};

const Select: React.FC<Props> = ({name, options, valueLabel}: Props) => {
    const [state, setState] = useRecoilState(userProfileFormState);

    return (
        <div className={'form_group'}>
            <SelectBase
                valueLabel={name === 'mobile_code' ? `+${state.mobile_code}` : ''}
                className="select_base"
                name={name}
                state={state}
                setState={setState}
                options={options}/>
        </div>
    );
};

export default Select;
