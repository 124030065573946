import {atom} from 'recoil'
import {ChargeMonth} from "../../model/response/ChargeResponse";
import {Station} from "../../model/response/StationListResponse";
import {StationTip} from "../../model/response/StationTipResponse";


interface IState {
    center: { lat: number, lng: number },
    zoom: number,
}

export const mapState = atom<IState>({
    key: 'mapState',
    default: {
        center: {lat: 37.575987740229, lng: 126.97677991349349},
        zoom: 9,
    }
})


interface IChargeStats {
    total: ChargeMonth
    monthly: ChargeMonth[]
}

export const chargeStatsState = atom<IChargeStats>({
    key: 'chargeStatsState',
    default: {
        total: {
            month: 0,
            sumEnergy: 0,
            sumFee: 0
        },
        monthly: []
    }
})

interface IStationDialog {
    isOpen: boolean
    station: Station
    tips: StationTip[]
}


export const stationDialogState = atom<IStationDialog>({
    key: 'stationDialogState',
    default: {
        isOpen: false,
        station: {
            cs_no: 0,
            cs_address: "",
            cs_name: "",
            cs_price: 0,
            cs_name_tag: 0,
            cs_note: "",
            cs_conn_type: 0,
            cs_status: 0,
            cs_lat: "",
            cs_lng: "",
            cs_address_line_1: "",
            cs_address_line_2: "",
            cs_address_city: "",
            cs_address_state: "",
            cs_address_zip_code: "",
            cs_address_country: "",
            cs_create_at: "",
            cs_modify_at: "",
            is_spot: false
        },
        tips: []
    }
})

interface ITipForm {
    content: string
}

export const stationTipFormState = atom<ITipForm>({
    key: 'stationTipFormState',
    default: {
        content: ''
    }
})

