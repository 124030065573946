import React from "react"

import LeftSideSpace from "./LeftSideSpace";
import CheckBox from "./form/CheckBox";
import ArrayCheckBox from "./form/ArrayCheckBox";

const LeftSideBar: React.FC = () => {




    return (
        <div className={'side_bar_container left_bar'}>
            <LeftSideSpace>
                <div className={'map_filter_list'}>
                    <div className={'filter_title'}>
                        Status
                    </div>
                    <div className={'filter_checkbox flex_row_between'}>
                        <span>Available</span>
                        <ArrayCheckBox name={'stationStatus' } value={1}/>
                    </div>
                    <div className={'filter_title'}>
                        Connector Type
                    </div>
                    <div className={'filter_checkbox flex_row_between'}>
                        <span>Level 1</span>
                        <ArrayCheckBox name={'stationConnType'} value={1} />
                    </div>
                    <div className={'filter_checkbox flex_row_between'}>
                        <span>Level 2 - J1772</span>
                        <ArrayCheckBox name={'stationConnType'} value={2} />
                    </div>
                    <div className={'filter_checkbox flex_row_between'}>
                        <span>Level 2 - NEMA 14-50</span>
                        <ArrayCheckBox name={'stationConnType'} value={3} />
                    </div>
                    <div className={'filter_checkbox flex_row_between'}>
                        <span>Level 2 - Tesla</span>
                        <ArrayCheckBox name={'stationConnType'} value={4} />
                    </div>
                    <div className={'filter_checkbox flex_row_between'}>
                        <span>DC - CHAdeMO</span>
                        <ArrayCheckBox name={'stationConnType'} value={5} />
                    </div>
                    <div className={'filter_checkbox flex_row_between'}>
                        <span>DC - Combo</span>
                        <ArrayCheckBox name={'stationConnType'} value={6} />
                    </div>
                    <div className={'filter_checkbox flex_row_between'}>
                        <span>Supercharger - Tesla</span>
                        <ArrayCheckBox name={'stationConnType'} value={7} />
                    </div>
                </div>
            </LeftSideSpace>

        </div>
    )
}

export default LeftSideBar