import {atom} from 'recoil'


interface ILoginForm {
    email: string
    password: string
}



export const loginFormState = atom<ILoginForm>({
    key: 'loginFormState',
    default: {
        email: '',
        password: ''
    }
})

