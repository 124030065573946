import React, {ReactNode} from "react"

interface IProps {
    children: ReactNode
}

const RightSideSpace: React.FC<IProps> = ({children}) => {

    return (
        <div className={'right_side_space_main'}>
            {children}
        </div>
    )
}

export default RightSideSpace