export const isIterableArray = (array: any[]) => Array.isArray(array) && !!array.length;

export const getConnTypeString = (type: number): string => {
    switch (type) {
        case 1:
            return 'Level 1'
        case 2:
            return 'Level 2 - J1772'
        case 3:
            return 'Level 2 - NEMA 14-50'
        case 4:
            return 'Level 2 - Tesla'
        case 5:
            return 'DC - ChadeMO'
        case 6:
            return 'DC - combo'
        case 7:
            return 'Supercharger - Tesla'

        default:
            return '';
    }
}


export const dataToForm = (initVal: any, data: any, form: any): object => {
    let value: any = {...initVal};
    Object.keys(form).forEach(key => {
        if (data[key] !== undefined) {
            value[key] = data[key]
        } else {

        }
    });

    return value;
}


export const getMonthName = (monthNumber: number) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', {month: 'short'});
}