import React, {useRef} from 'react'
import {isIterableArray} from "../../../util/common";

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    state: any
    setState: any
}

const Input: React.FC<Props> = ({state, setState, ...props}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null)
    return (
        <div className={'input_base_wrapper'} style={{width: '100%'}}>
            <input
                {...props}
                ref={inputRef}
                data-testid={props.name}
                readOnly
                onFocus={e => {
                    e.target.readOnly = false
                }}
                onChange={e => {
                    if (e.target.name.includes('.')) {
                        const splitName = e.target.name.split('.');
                        if (isIterableArray(splitName)) {
                            setState({
                                ...state,
                                [splitName[0]]: {
                                    ...state[splitName[0]],
                                    [splitName[1]]: e.target.value
                                }
                            })
                        }
                    } else {
                        setState({...state, [e.target.name]: e.target.value})
                    }
                }}
            />
        </div>
    )
}

export default Input
