import {StationListResponse} from "../model/response/StationListResponse";
import {StationListRequest} from "../model/request/StationListRequest";
import {AuthorizeHttpClient} from "../api/authorize-http-client-decorator";
import {API_ADDRESS} from "../api/ApiFactory";
import {HttpStatusCode} from "../api/http-client";
import {ApiError} from "../model/errors/ApiError";
import {IBaseResponse} from "../model/response/base-response-model";
import {UnexpectedError} from "../model/errors/unexpected-error";
import {StationTipListResponse, StationTipResponse} from "../model/response/StationTipResponse";
import {StationTipRequest, UpdateStationTipRequest} from "../model/request/StationTipRequest";
import {BasicResponse} from "../model/response/BasicResponse";
import {StationSpotRequest} from "../model/request/StationSpotRequest";
import {StationSpotListResponse} from "../model/response/StationSpotListResponse";

export const getStationList = async (params: StationListRequest): Promise<StationListResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/station/',
        method: 'get',
        params: {
            status: params.status,
            type: params.type.join(','),
            lat: params.lat,
            lng: params.lng
        }
    })

    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as StationListResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default:
            throw new UnexpectedError()
    }
}


export const getStationSpotList = async (params: StationSpotRequest): Promise<StationSpotListResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/station/spot',
        method: 'get',
        params: params
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as StationSpotListResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default:
            throw new UnexpectedError()
    }
}

export const stationSpotRegisterAndDelete = async (stationNo: number): Promise<BasicResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/station/spot',
        method: 'post',
        body: {
            stationNo: stationNo
        }
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as BasicResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default:
            throw new UnexpectedError()
    }
}

export const createStationTip = async (params: StationTipRequest): Promise<StationTipResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/station/' + params.stationNo + '/tip',
        method: 'post',
        body: params.dto
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.created:
            return httpResponse.body as StationTipResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default:
            throw new UnexpectedError()
    }
}

export const getStationTips = async (stationNo: number): Promise<StationTipListResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/station/' + stationNo + '/tip',
        method: 'get',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as StationTipListResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default:
            throw new UnexpectedError()
    }
}

export const updateStationTip = async (params: UpdateStationTipRequest): Promise<StationTipResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/station/' + params.stationNo + '/tip/' + params.tipNo,
        method: 'put',
        body: params.dto
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as StationTipResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default:
            throw new UnexpectedError()
    }
}

export const deleteStationTip = async (stationNo: number, tipNo: number): Promise<BasicResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/station/' + stationNo + '/tip/' + tipNo,
        method: 'delete',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
            return httpResponse.body as BasicResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default:
            throw new UnexpectedError()
    }
}
