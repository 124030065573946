import CheckBoxBase from 'component/common/checkbox/CheckBoxBase';
import React from 'react';
import {useRecoilState} from 'recoil';
import {myAccountDetailState, userProfileFormState} from "../atoms";

type Props = {
    name: string
    label: string
    value?: any
    disabled?: boolean
};

const CheckBox: React.FC<Props> = ({name, value, disabled}: Props) => {
    const [state, setState] = useRecoilState(userProfileFormState);

    return (
        <div className={'create_checkbox_wrapper'}>
            <CheckBoxBase
                disabled={disabled}
                className="select_base"
                name={name}
                state={state}
                setState={setState}
                value={value}
            />
        </div>
    );
};

export default CheckBox;
