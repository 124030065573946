import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import pencilIcon from "../../assets/icon/pencil.svg";
import {Col, Row} from "react-bootstrap";
import {presentationError} from "errors/presentationError";
import {createCard} from "../../repository/PaymentRepository";
import {createCardFormState, profileCreditCardState} from "./atoms";
import {useRecoilValue, useSetRecoilState} from "recoil";
import PcInput from "./form/PcInput";


const ModifyCreditCardDialog: React.FC = () => {
    const [show, setShow] = useState(false);
    const createCardForm = useRecoilValue(createCardFormState);
    const setProfileCard = useSetRecoilState(profileCreditCardState);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const editCreditCard = async () => {
        try {
            const result = await createCard(createCardForm);


            if (result.success && result.data) {
                handleClose();
                setProfileCard(result.data);
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'edit credit card',
                alert: true
            });
        }
    }

    return (
        <div className={'dialog_wrapper'}>
            <button onClick={handleShow} className={'active_btn'}>
                <img src={pencilIcon} alt={'modify_icon'}/>
            </button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Payment Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <div className={'form_group'}>
                                <label>Card Number *</label>
                                <PcInput type={'text'} name={'number'} placeholder={''} value={createCardForm.number}/>
                            </div>
                        </div>
                        <Row>
                            <Col xs={4}>
                                <div className={'form_group'}>
                                    <label>MM / YY</label>
                                    <PcInput type={'text'} name={'validity'} placeholder={''}
                                             value={createCardForm.validity}/>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className={'form_group'}>
                                    <label>CVC</label>
                                    <PcInput type={'text'} name={'cvc'} placeholder={''} value={createCardForm.cvc}/>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className={'form_group'}>
                                    <label>Zip/Postal Code</label>
                                    <PcInput type={'text'} name={'zip_code'} placeholder={''}
                                             value={createCardForm.zip_code}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose}>
                        Cancel
                    </button>
                    <button onClick={editCreditCard}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModifyCreditCardDialog;