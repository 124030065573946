import React, {useEffect, useState} from "react";
import classNames from "classnames";

type IProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    state: any
    setState: any
    name: string
    value: any
    handler: (checked: boolean) => void
};

const ArrayCheckBoxBase: React.FC<IProps> = ({state, setState, name,  value, handler}) => {

    const [array, setArray] = useState<string[]>([]);

    useEffect(() => {
        setArray([]);
    }, [])

    useEffect(() => {
        setArray([]);
        if (state[name]) {
            if (Array.isArray(state[name])) {
                setArray(state[name])
            }
        }
    }, [state, name])


    return (
                    <input
                        name={name}
                        type={'checkbox'}
                        checked={array.includes(value)}
                        onChange={e => handler(e.target.checked)}/>

    )
}


export default ArrayCheckBoxBase;