import {atom} from 'recoil'
import {UserVehicle} from "../../model/response/VehicleResponse";
import {CreateVehicleRequest} from "../../model/request/CreateVehicleRequest";
import {UpdateUserRequest} from "../../model/request/UpdateUserRequest";
import {CreateCardRequest} from "../../model/request/CreateCardRequest";
import {PaymentCard} from "../../model/response/CardResponse";
import {PointCardRequest, RegisterPointCardRequest} from "../../model/request/PointCardRequest";
import {PointCard} from "../../model/response/PointCardResponse";

interface User {
    no: number
    username: string
    account_number: string
    first_name: string
    last_name: string
    email: string
    mobile_code: string
    mobile_number: string
    language: string
    address_line_1: string
    address_line_2: string
    address_city: string
    address_state: string
    address_zip_code: string
    address_country: string
    shipping_address_line_1: string
    shipping_address_line_2: string
    shipping_address_city: string
    shipping_address_state: string
    shipping_address_zip_code: string
    shipping_address_country: string
    noti_fully: number
    noti_charging: number
    noti_receipt: number
    noti_email: number
    noti_text: number
    noti_phone_code: string
    noti_phone_number: string
    noti_email_address: string
    profile_img: number
}

export const myAccountDetailState = atom<User>({
    key: 'myAccountDetailState',
    default: {
        no: 0,
        username: '',
        account_number: '',
        first_name: '',
        last_name: '',
        email: '',
        mobile_code: '',
        mobile_number: '',
        language: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_state: '',
        address_zip_code: '',
        address_country: '',
        shipping_address_line_1: '',
        shipping_address_line_2: '',
        shipping_address_city: '',
        shipping_address_state: '',
        shipping_address_zip_code: '',
        shipping_address_country: '',
        noti_fully: 0,
        noti_charging: 0,
        noti_receipt: 0,
        noti_email: 0,
        noti_text: 0,
        noti_phone_code: '',
        noti_phone_number: '',
        noti_email_address: '',
        profile_img: 0,
    }
})

export const userProfileFormInit = {
    username: '',
    password: '',
    newPassword: '',
    first_name: '',
    last_name: '',
    email: '',
    mobile_code: '',
    mobile_number: '',
    language: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_state: '',
    address_zip_code: '',
    address_country: '',
    shipping_address_line_1: '',
    shipping_address_line_2: '',
    shipping_address_city: '',
    shipping_address_state: '',
    shipping_address_zip_code: '',
    shipping_address_country: '',
    noti_fully: 0,
    noti_charging: 0,
    noti_receipt: 0,
    noti_email: 0,
    noti_text: 0,
    noti_phone_code: 0,
    noti_phone_number: 0,
    noti_email_address: '',
    profile_img: 0,
}

export const userProfileFormState = atom<UpdateUserRequest>({
    key: 'userProfileFormState',
    default: userProfileFormInit
})

export const createVehicleFormState = atom<CreateVehicleRequest>({
    key: `createVehicleFormState`,
    default: {
        make: '',
        model: '',
        year: 0,
        color: '',
        primary: 0
    }
})

interface ManageVehiclePage {
    vehicles: UserVehicle[]
}

export const manageVehiclesPageState = atom<ManageVehiclePage>({
    key: 'manageVehiclesPageState',
    default: {
        vehicles: []
    }
})


export const createCardFormState = atom<CreateCardRequest>({
    key: `createCardFormState`,
    default: {
        number: "",
        validity: "",
        cvc: "",
        zip_code: "",
    }
})

export const profileCreditCardState = atom<PaymentCard>({
    key: 'profileCreditCardState',
    default: {
        pc_no: 0,
        pc_user_no: 0,
        pc_number: "",
        pc_validity: "",
        pc_cvc: "",
        pc_zip_code: "",
        pc_create_at: "",
        pc_modify_at: "",
    }
})

export const createPointCardFormState = atom<PointCardRequest>({
    key: `createPointCardFormState`,
    default: {
        shipping_address_line_1: "",
        shipping_address_line_2: "",
        shipping_address_city: "",
        shipping_address_state: "",
        shipping_address_zip_code: "",
        shipping_address_country: "",
        cnt: 1
    }
})

export const chargePointCardState = atom<PointCard[]>({
    key: 'chargePointCardState',
    default: []
})

export const modifyPointCardFormState = atom<RegisterPointCardRequest>({
    key: 'modifyPointCardFormState',
    default: {
        serial_number: "",
        nickname: ""

    }
})
