import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import TextArea from "../form/TextArea";
import {presentationError} from "../../../errors/presentationError";
import {createStationTip, updateStationTip} from "../../../repository/StationRepository";
import {useRecoilState, useRecoilValue} from "recoil";
import {stationDialogState, stationTipFormState} from "../atoms";
import {notificationAlert} from "../../../util/alert";


interface IProps {
    mode: 'modify' | 'create'
    show: boolean
    setShow: (show: boolean) => void
    tipNo?: number
}

const EditTipDialog: React.FC<IProps> = ({mode, show, setShow, tipNo}) => {
    const tipForm = useRecoilValue(stationTipFormState);
    const [dialogState, setDialogState] = useRecoilState(stationDialogState);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const createTip = async () => {
        try {
            if (tipForm.content.length <= 0) {
                notificationAlert('notification', 'empty content');
                return;
            }
            const result = await createStationTip({
                stationNo: dialogState.station.cs_no,
                dto: {
                    content: tipForm.content
                }
            });

            if (result.success && result.data) {
                setDialogState(old => ({...old, tips: [result.data, ...old.tips]}));
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'create tip',
                alert: true
            });
        }
    }

    const updateTip = async () => {
        try {
            if (!tipNo){
                return;
            }
            if (tipForm.content.length <= 0) {
                notificationAlert('notification', 'empty content');
                return;
            }
            const result = await updateStationTip({
                stationNo: dialogState.station.cs_no,
                tipNo: tipNo,
                dto: {
                    content: tipForm.content
                }
            });

            if (result.success && result.data) {
                const newTips = [...dialogState.tips];
                const clickIndex = newTips.findIndex(tip => tip.cst_no === tipNo);
                if (clickIndex >= 0) {
                    newTips[clickIndex] = result.data;
                }

                setDialogState(old => ({...old, tips: newTips}));
                handleClose();
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'update tip',
                alert: true
            });
        }
    }

    return (
        <div className={'dialog_wrapper'}>
            <button onClick={handleShow} id={'leave_tip_btn'}>Leave a Tip</button>
            <Modal show={show} onHide={handleClose} className={'edit_tip_dialog_wrapper'}>
                <Modal.Header closeButton>
                    <Modal.Title>Leave a Tip</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div style={{padding: '8px'}}>
                            <div className={'form_group'}>
                                <label>Tip</label>
                                <TextArea name={'content'}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose}>
                        Cancel
                    </button>
                    <button onClick={mode === 'create' ? createTip : updateTip}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditTipDialog;