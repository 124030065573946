import {BaseError} from "../model/errors/base-error";
import {notificationAlert} from "../util/alert";
import {logout} from "../repository/AuthRepository";

interface PrError {
    type: string,
    error: any,
    alert?: boolean
}

export const presentationError = (params: PrError) => {
    console.log(params)
    if (params.error instanceof BaseError) {
        if (params.alert) {
            notificationAlert('오류', params.error.message);
            if (params.error.errorCode === 401) {
                handleClickLogout();
            }
        }
    } else {
        if (params.error.errorCode === 401) {
            handleClickLogout();
            return;
        }
        if (params.alert) {
            if (params.error.message) {
                notificationAlert('오류', params.error.message);
            } else {
                notificationAlert('오류', `${params.type}에 문제가 발생하였습니다.`);
            }
        }
    }
}

export const handleClickLogout = async () => {
    notificationAlert('notification', 'retry Login');
    const result = await logout();
    if (result.success) {
        window.location.href = '/';
    }
}