import {useRecoilState} from 'recoil'
import React from 'react'
import {InputBase} from "../../../common";
import {modifyPointCardFormState} from "../../atoms";

type Props = {
    type: string
    name: string
    placeholder: string
    disabled?: boolean
    value?: string
}

const AcInput: React.FC<Props> = ({type, name, placeholder, disabled = false, value}: Props) => {
    const [state, setState] = useRecoilState(modifyPointCardFormState)

    return (
        <InputBase
            value={value}
            disabled={disabled}
            className={'auth_input'}
            type={type}
            name={name}
            placeholder={placeholder}
            state={state}
            setState={setState}
        />
    )
}

export default AcInput
