import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import brandLogo from '../../assets/image/brand_logo.png';
import dashboardIcon from '../../assets/icon/link_dashboard.svg';
import carIcon from '../../assets/icon/link_car.svg';
import chargeSessionIcon from '../../assets/icon/electricity_circle.svg';
import classNames from "classnames";
import {presentationError} from "errors/presentationError";
import {logout} from "../../repository/AuthRepository";
import {getUserName} from "../../repository/UserRepository";
import {useRecoilState} from "recoil";
import {userInfoState} from "./atoms";

const Navbar: React.FC = () => {

    const location = useLocation();
    const [userInfo, setUserInfo] = useRecoilState(userInfoState);

    useEffect(() => {
        getUserInfo();
    }, [])

    const handleLogout = async () => {
        try {
            const result = await logout();
            if (result.success) {
                window.location.href = '/';
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'logout',
                alert: true
            });
        }
    }


    const getUserInfo = async () => {
        try {
            const result = await getUserName();

            if (result.success && result.data) {
                const userInfoData = result.data;
                setUserInfo(old => ({
                    ...old,
                    first_name: userInfoData.first_name,
                    last_name: userInfoData.last_name,
                    step1: userInfoData.step1,
                    step3: userInfoData.step3,
                    step4: userInfoData.step4,
                }))
            }
        } catch (err) {
            presentationError({
                error: err,
                type: 'get user info',
                alert: true
            });
        }
    }


    return (
        <nav className={'navbar_wrapper'}>
            <div className={'main_nav flex_row_between'}>
                <div className={'brand_logo'}>
                    <Link to={'/'}>
                        <img src={brandLogo} alt={'brand_logo'}/>
                    </Link>
                </div>
                <div className={'user_name'}>
                    <div className="user">
                        <span className="welcome">Welcome,</span><br/>
                        <button onClick={handleLogout} className="logout_btn">
                            {`${userInfo.last_name} ${userInfo.first_name}`} - Logout
                        </button>
                    </div>
                </div>
            </div>
            <div className={'nav_links'}>
                <ul>
                    <li>
                        <Link to={'/'}>
                            <img src={dashboardIcon} alt={'link_icon'}/>
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to={'/my/detail'}>
                            <img src={carIcon} alt={'link_icon'}/>
                            My Account
                        </Link>
                    </li>
                </ul>
            </div>
            {location.pathname.includes('/my') ?
                <div className={'sub_nav'}>
                    <ul>
                        <li>
                            <Link className={classNames({
                                'active': location.pathname === '/my/detail'
                            })} to={'/my/detail'}>
                                Account Details
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={classNames({
                                    'active': location.pathname === '/my/statement'
                                })}
                                to={'/my/statement'}>
                                Monthly Statement
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={classNames({
                                    'active': location.pathname === '/my/cards'
                                })}
                                to={'/my/cards'}>
                                Manage Cards
                            </Link>
                        </li>
                        {/*<li>*/}
                        {/*    <Link*/}
                        {/*        className={classNames({*/}
                        {/*            'active': location.pathname === '/my/vehicles'*/}
                        {/*        })}*/}
                        {/*        to={'/my/vehicles'}>*/}
                        {/*        Manage Eletric Vehicles*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                    </ul>
                </div>
                :
                <div className={'charging_session_header'}>
                    <img src={chargeSessionIcon} alt={'session_icon'}/>
                    <span>No Active charging sessions</span>
                </div>
            }
        </nav>
    )
}


export default Navbar;