import React, {ReactNode} from "react";
import Navbar from "../nav/Navbar";


interface IProps {
    children: ReactNode
}

const MainLayout:React.FC<IProps> = ({children}) => {


    return(
        <div className={'main_layout_wrapper'}>
            <Navbar/>
            {children}
        </div>
    )
}

export default MainLayout;
