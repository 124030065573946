import {ISelectItem} from "../../common/select/SelectBase";

export const stateOptions:ISelectItem[] = [
    {
        "value": "Alabama",
        "txt": "Alabama"
    },
    {
        "value": "Alaska",
        "txt": "Alaska"
    },
    {
        "value": "American Samoa",
        "txt": "American Samoa"
    },
    {
        "value": "Arizona",
        "txt": "Arizona"
    },
    {
        "value": "Arkansas",
        "txt": "Arkansas"
    },
    {
        "value": "Armed Forces Americas",
        "txt": "Armed Forces Americas"
    },
    {
        "value": "Armed Forces Europe, Middle East,  Canada",
        "txt": "Armed Forces Europe, Middle East,  Canada"
    },
    {
        "value": "Armed Forces Pacific",
        "txt": "Armed Forces Pacific"
    },
    {
        "value": "California",
        "txt": "California"
    },
    {
        "value": "Colorado",
        "txt": "Colorado"
    },
    {
        "value": "Connecticut",
        "txt": "Connecticut"
    },
    {
        "value": "Delaware",
        "txt": "Delaware"
    },
    {
        "value": "District of Columbia",
        "txt": "District of Columbia"
    },
    {
        "value": "Federated States of Micronesia",
        "txt": "Federated States of Micronesia"
    },
    {
        "value": "Florida",
        "txt": "Florida"
    },
    {
        "value": "Georgia",
        "txt": "Georgia"
    },
    {
        "value": "Guam",
        "txt": "Guam"
    },
    {
        "value": "Hawaii",
        "txt": "Hawaii"
    },
    {
        "value": "Idaho",
        "txt": "Idaho"
    },
    {
        "value": "Illinois",
        "txt": "Illinois"
    },
    {
        "value": "Indiana",
        "txt": "Indiana"
    },
    {
        "value": "Iowa",
        "txt": "Iowa"
    },
    {
        "value": "Kansas",
        "txt": "Kansas"
    },
    {
        "value": "Kentucky",
        "txt": "Kentucky"
    },
    {
        "value": "Louisiana",
        "txt": "Louisiana"
    },
    {
        "value": "Maine",
        "txt": "Maine"
    },
    {
        "value": "Marshall Islands",
        "txt": "Marshall Islands"
    },
    {
        "value": "Maryland",
        "txt": "Maryland"
    },
    {
        "value": "Massachusetts",
        "txt": "Massachusetts"
    },
    {
        "value": "Michigan",
        "txt": "Michigan"
    },
    {
        "value": "Minnesota",
        "txt": "Minnesota"
    },
    {
        "value": "Mississippi",
        "txt": "Mississippi"
    },
    {
        "value": "Missouri",
        "txt": "Missouri"
    },
    {
        "value": "Montana",
        "txt": "Montana"
    },
    {
        "value": "Nebraska",
        "txt": "Nebraska"
    },
    {
        "value": "Nevada",
        "txt": "Nevada"
    },
    {
        "value": "New Hampshire",
        "txt": "New Hampshire"
    },
    {
        "value": "New Jersey",
        "txt": "New Jersey"
    },
    {
        "value": "New Mexico",
        "txt": "New Mexico"
    },
    {
        "value": "New York",
        "txt": "New York"
    },
    {
        "value": "North Carolina",
        "txt": "North Carolina"
    },
    {
        "value": "North Dakota",
        "txt": "North Dakota"
    },
    {
        "value": "Northern Mariana Islands",
        "txt": "Northern Mariana Islands"
    },
    {
        "value": "Ohio",
        "txt": "Ohio"
    },
    {
        "value": "Oklahoma",
        "txt": "Oklahoma"
    },
    {
        "value": "Oregon",
        "txt": "Oregon"
    },
    {
        "value": "Palau",
        "txt": "Palau"
    },
    {
        "value": "Pennsylvania",
        "txt": "Pennsylvania"
    },
    {
        "value": "Puerto Rico",
        "txt": "Puerto Rico"
    },
    {
        "value": "Rhode Island",
        "txt": "Rhode Island"
    },
    {
        "value": "South Carolina",
        "txt": "South Carolina"
    },
    {
        "value": "South Dakota",
        "txt": "South Dakota"
    },
    {
        "value": "Tennessee",
        "txt": "Tennessee"
    },
    {
        "value": "Texas",
        "txt": "Texas"
    },
    {
        "value": "Utah",
        "txt": "Utah"
    },
    {
        "value": "Vermont",
        "txt": "Vermont"
    },
    {
        "value": "Virgin Islands",
        "txt": "Virgin Islands"
    },
    {
        "value": "Virginia",
        "txt": "Virginia"
    },
    {
        "value": "Washington",
        "txt": "Washington"
    },
    {
        "value": "West Virginia",
        "txt": "West Virginia"
    },
    {
        "value": "Wisconsin",
        "txt": "Wisconsin"
    },
    {
        "value": "Wyoming",
        "txt": "Wyoming"
    }
]
