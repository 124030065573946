import React from 'react';
import {useRecoilState} from 'recoil';
import {loginFormState} from "./atoms";
import {InputBase} from "../../common";


type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    type: string;
    name: string;
    placeholder: string;
    maxLength: number
};

const Input: React.FC<Props> = ({type, name, placeholder, maxLength, ...props}: Props) => {
    const [state, setState] = useRecoilState(loginFormState);

    return (
        <InputBase
            {...props}
            className="auth_input"
            maxLength={maxLength}
            type={type}
            name={name}
            placeholder={placeholder}
            state={state}
            setState={setState}
        />
    );
};

export default Input;
