import React from "react";
import classNames from "classnames";


type IProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    state: any
    setState: any
    name: string
    label?: string
    value?: any
    noLabel?: boolean
    disabled?: boolean
};

const CheckBoxBase: React.FC<IProps> = ({state, setState, name, label, value, noLabel, disabled}) => {


    const handleChange = (checked: boolean) => {
        if (!value) {
            if (typeof value === 'number') {
                setState({...state, [name]: 0});
            } else {
                setState({...state, [name]: checked})
            }
        } else {
            if (state[name] === value) {
                if (typeof value === 'number') {
                    setState({...state, [name]: 0});
                } else {
                    setState({...state, [name]: ''});
                }
            } else {
                setState({...state, [name]: value})
            }
        }
    }

    return (
        <>
            {noLabel ?
                <input
                    name={name}
                    type={'checkbox'}
                    checked={value ? state[name] === value : state[name]}
                    onChange={e => handleChange(e.target.checked)}/>
                :
                <div className={"form-group"}>
                    <div className={'form_check'}>
                        <label className={classNames({
                            'check_label_wrapper': true,
                            'checked': value ? state[name] === value : state[name],
                            'disable': disabled
                        })}>
                            {label}
                            <input
                                name={name}
                                type={'checkbox'}
                                checked={value ? state[name] === value : state[name]}
                                onChange={e => handleChange(e.target.checked)}/>
                            <span className="checkmark"/>
                        </label>
                    </div>
                </div>
            }

        </>
    )
}


export default CheckBoxBase;