import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import AcInput from "./form/AcInput";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {chargePointCardState, modifyPointCardFormState} from "../atoms";
import {presentationError} from "../../../errors/presentationError";
import {deletePointCard, registerPointCard} from "../../../repository/PaymentRepository";

interface IProps {
    show: boolean
    setShow: (bool: boolean) => void
    mode: 'create' | 'modify'
    setMode: (mode: 'create' | 'modify') => void
    cardNo?: number
}

const ActivateCardDialog: React.FC<IProps> = ({show, setShow, mode, cardNo, setMode}) => {

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const resetFormState = useResetRecoilState(modifyPointCardFormState);
    const modifyPointCardForm = useRecoilValue(modifyPointCardFormState);
    const [chargePointCard, setChargePointCard] = useRecoilState(chargePointCardState);

    const handleClickCreate = () => {
        resetFormState();
        setMode('create');
        handleShow();
    }

    const activatePointCard = async () => {
        try {
            const result = await registerPointCard(modifyPointCardForm);

            if (result.success && result.data) {
                const pcIndex = chargePointCard.findIndex(pc => pc.pc_no === cardNo);
                if (pcIndex !== -1) {
                    let newPointCards = [...chargePointCard];
                    newPointCards[pcIndex] = result.data;
                    setChargePointCard(([...newPointCards]));
                }

                handleClose();
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'activate point card',
                alert: true
            });
        }
    }


    const deleteChargePointCard = async () => {
        try {
            if (cardNo) {
                const result = await deletePointCard(cardNo);

                if (result.success) {
                    const newPointCards = chargePointCard.filter(pc => pc.pc_no !== cardNo);
                    setChargePointCard([...newPointCards]);
                    handleClose();
                }
            }
        } catch (err) {
            presentationError({
                error: err,
                type: 'delete point card',
                alert: true
            });
        }
    }


    return (
        <div className={'dialog_wrapper'}>
            <button onClick={handleClickCreate} className={'active_btn'}>Activate</button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Activate Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'activate_card_dialog'}>
                        {mode === 'create' &&
                            <p>Enter the serial number found on the back of your card. Give it a nickname to identify it
                                later.</p>}
                        <span>Card info</span>
                        <div style={{padding: '8px'}}>
                            <div className={'form_group'}>
                                <label>Serial Number *</label>
                                <AcInput disabled={mode === 'modify'} value={modifyPointCardForm.serial_number}
                                         name={'serial_number'}
                                         placeholder={''} type={'text'}/>
                            </div>
                            <div className={'form_group'}>
                                <label>Card Nickname *</label>
                                <AcInput value={modifyPointCardForm.nickname} name={'nickname'} placeholder={''}
                                         type={'text'}/>
                            </div>
                        </div>
                    </div>
                    {mode === 'modify' &&
                        <div className={'del_dialog_btn'}>
                            <button onClick={deleteChargePointCard}>Delete Point Card</button>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose}>
                        Cancel
                    </button>
                    <button onClick={activatePointCard}>
                        Activate
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ActivateCardDialog;