import React from 'react';
import {useRecoilState} from 'recoil';
import TextAreaBase from "../../common/textArea/TextAreaBase";
import {stationTipFormState} from "../atoms";

type Props = {
    name: string
};

const TextArea: React.FC<Props> = ({name}: Props) => {
    const [state, setState] = useRecoilState(stationTipFormState);

    return (
            <TextAreaBase
                className="select_base"
                name={name}
                state={state}
                setState={setState}
            />
    );
};

export default TextArea;
