import React, {useCallback, useRef} from "react";

type IProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
    state: any
    setState: any
    name: string
    expandHeight?: number
    value?: string
};

const TextAreaBase: React.FC<IProps> = ({state, setState, name, expandHeight, value, ...props}) => {
    const textRef = useRef<HTMLTextAreaElement>(null);

    return (
        <div className={'text_area_base_wrapper'}>
            <textarea
                {...props}
                ref={textRef}
                // onInput={() => handleResizeHeight(state, name)}
                value={value ? value : state[name] ?? ''}
                name={name}
                onChange={(e) => {
                    setState({...state, [e.target.name]: e.target.value});
                }}/>
        </div>
    )
}


export default TextAreaBase;