import React from "react";
import searchIcon from '../../assets/icon/search.svg';


const SearchChargeNear: React.FC = () => {

    return (
        <div className={'search_charger_near_wrapper'}>
            <div className={'search_prompt'}>
                I want to charge near
            </div>
            <div className={'search_input'}>
                <input type={'text'} placeholder={'Enter a location'}/>
            </div>
            <div className={'search_btn'}>
                <button>
                    <img src={searchIcon} alt={'search_btn'}/>
                    Search
                </button>
            </div>
        </div>
    )
}


export default SearchChargeNear;