import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import cardIcon from "../../../assets/icon/card.svg";
import CreateCardDialog from "./CreateCardDialog";
import EditCardDialog from "./ActivateCardDialog";
import ActivateCardDialog from "./ActivateCardDialog";
import {presentationError} from "../../../errors/presentationError";
import {getPointCard} from "../../../repository/PaymentRepository";
import {useRecoilState, useSetRecoilState} from "recoil";
import {chargePointCardState, createPointCardFormState, modifyPointCardFormState} from "../atoms";
import {PaymentCard} from "../../../model/response/CardResponse";
import {PointCard} from "../../../model/response/PointCardResponse";

const ManageCardPage: React.FC = () => {
    const [chargePointCard, setChargePointCard] = useRecoilState(chargePointCardState);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [dialogMode, setDialogMode] = useState<'create' | 'modify'>('create');
    const [cardNo, setCardNo] = useState<number>(0);
    const setModifyPointCardForm = useSetRecoilState(modifyPointCardFormState);

    useEffect(() => {
        getUserPointCard();
    }, [])


    const getUserPointCard = async () => {
        try {
            const result = await getPointCard();

            if (result.data) {
                setChargePointCard(result.data);
            }
        } catch (err) {
            presentationError({
                error: err,
                type: 'get point card',
                alert: true
            });
        }
    }

    const handleClickCard = (card: PointCard) => {
        setDialogMode('modify');
        setCardNo(card.pc_no);
        setModifyPointCardForm({
            serial_number: card.pc_serial_number,
            nickname: card.pc_nickname
        });

        setShowDialog(true);
    }

    return (
        <div className={'manage_card_page page_container'}>
            <h3 id={'account_page_title'}>Manage Cards</h3>
            <Row className={'cards'}>
                <Col xs={12} md={4} xl={4}>
                    <div className={'card_item add_card'}>
                        <div className={'plus_ball'}>
                            <svg className="sc-gKPRtg etvnay cp-icon" xmlns="http://www.w3.org/2000/svg" version="1.1"
                                 preserveAspectRatio="xMidYMid meet" viewBox="4 4 16 16"
                                 data-qa-id="add_charging_card_icon"
                                 aria-hidden="true"><title></title>
                                <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path>
                            </svg>
                        </div>
                        <h5>New Charging Card</h5>
                        <div className={'add_card_btns'}>
                            <CreateCardDialog/>
                            <ActivateCardDialog cardNo={cardNo}
                                                mode={dialogMode}
                                                setMode={setDialogMode}
                                                setShow={setShowDialog}
                                                show={showDialog}/>
                        </div>
                    </div>
                </Col>
                {chargePointCard.map(card => <Col key={card.pc_no} onClick={() => handleClickCard(card)} xs={12} md={4} xl={4}>
                    <div className={'card_item'}>
                        <div className={'card_img'}>
                            <img src={cardIcon} alt={'card_icon'}/>
                        </div>
                        <h5>{card.pc_nickname ?? 'My Card'}</h5>
                        <p>{card.pc_serial_number}</p>
                    </div>
                </Col>)}

            </Row>
        </div>
    )
}

export default ManageCardPage;