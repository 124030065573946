import React from 'react';
import {useRecoilState} from 'recoil';
import {sidebarState} from "../atoms";
import ArrayCheckBoxBase from "../../common/checkbox/ArrayCheckBoxBase";

type Props = {
    name: string
    value: number
};

const ArrayCheckBox: React.FC<Props> = ({name, value}: Props) => {
    const [state, setState] = useRecoilState(sidebarState);

    const handleChange = (checked: boolean) => {
        if (checked) {
            setState(old => ({...old, stationConnType: [...old.stationConnType, value]}))
        } else {
            const newValue = state.stationConnType.filter(type => type !== value);
            setState(old => ({...old, stationConnType: [...newValue]}));
        }
    }


    return (
        <ArrayCheckBoxBase
            className="select_base"
            name={name}
            state={state}
            setState={setState}
            value={value}
            handler={handleChange}/>
    );
};

export default ArrayCheckBox;
