import React, {useState} from "react"
import RightSideSpace from "./RightSideSpace";
import chargeStationIcon from '../../assets/icon/chargingStation.svg';
import recentIcon from '../../assets/icon/recent.svg';
import heartIcon from '../../assets/icon/heart.svg';
import trafficJamIcon from '../../assets/icon/trafficJam.svg';
import classNames from "classnames";
import ChargeSpotsTab from "../main/tab/ChargeSpotsTab";
import RecentSpotsTab from "../main/tab/RecentSpotsTab";
import ChargeMySpotsTab from "../main/tab/ChargeMySpotsTab";


const RightSideBar: React.FC = () => {

    const [tabNum, setTabNum] = useState<number>(1);

    return (
        <div className={'side_bar_container right_bar'}>
            <RightSideSpace>
                <div className={'charger_info_panel h-100'}>
                    <ul className={'panel_tab'}>
                        <li
                            className={classNames({
                                'active': tabNum === 1
                            })}
                            onClick={() => setTabNum(1)}>
                            <img src={chargeStationIcon} alt={'tab_icon'}/>
                            Charge Spots
                        </li>
                        <li
                            className={classNames({
                                'active': tabNum === 2
                            })}
                            onClick={() => setTabNum(2)}>
                            <img src={recentIcon} alt={'tab_icon'}/>
                            Recent
                        </li>
                        <li
                            className={classNames({
                                'active': tabNum === 3
                            })}
                            onClick={() => setTabNum(3)}>
                            <img src={heartIcon} alt={'tab_icon'}/>
                            My Spots
                        </li>
                        <li
                            className={classNames({
                                'active': tabNum === 4
                            })}
                            onClick={() => setTabNum(4)}>
                            <img src={trafficJamIcon} alt={'tab_icon'}/>
                            Waitlist
                        </li>
                    </ul>
                    <div className={'sort_bar'}>
                        <span>Sort by</span>
                        <select className="driverSelect">
                            <option value="visited_date">Recent</option>
                            <option value="distance">Distance</option>
                            <option value="installation_date">Installation Date</option>
                            <option value="estimated_fee">Estimated Fee</option>
                        </select>
                    </div>

                    {tabNum === 1 && <ChargeSpotsTab/>}
                    {tabNum === 2 && <RecentSpotsTab/>}
                    {tabNum === 3 && <ChargeMySpotsTab/>}
                </div>
            </RightSideSpace>

        </div>
    )
}

export default RightSideBar