import React, {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {stationDialogState, stationTipFormState} from "../atoms";
import fillHeartIcon from "../../../assets/icon/fill_heart.svg";
import emptyHeartIcon from "../../../assets/icon/empty_heart.svg";
import dollarIcon from "../../../assets/icon/dollar.svg";
import ideaIcon from "../../../assets/icon/idea.svg";
import chargerIcon from "../../../assets/icon/charging.svg";
import {presentationError} from "../../../errors/presentationError";
import {deleteStationTip, getStationTips, stationSpotRegisterAndDelete} from "../../../repository/StationRepository";
import EditTipDialog from "./EditTipDialog";
import {stationsState} from "../../sidebar/atoms";


const StationInfoDialog: React.FC = () => {
    const [dialogState, setDialogState] = useRecoilState(stationDialogState);
    const [stations, setStations] = useRecoilState(stationsState);
    const [dialogMode, setDialogMode] = useState<'modify' | 'create'>('create');
    const [selTipNo, setSelTipNo] = useState<number>(0);
    const [showDialog, setShowDialog] = useState(false);
    const setFormState = useSetRecoilState(stationTipFormState);
    const resetDialog = useResetRecoilState(stationDialogState);
    const handleClose = () => resetDialog();

    useEffect(() => {
        if (dialogState.station.cs_no === 0) return;
        getStationTip();
    }, [dialogState.station.cs_no])

    const handleClickMySpot = async () => {
        try {
            if (dialogState.station.cs_no === 0) return;
            const result = await stationSpotRegisterAndDelete(dialogState.station.cs_no);

            if (result.success) {
                setDialogState(old => ({
                    ...old, station: {
                        ...old.station,
                        is_spot: !old.station.is_spot
                    }
                }));
                let newStations = [...stations];
                const clickIndex = newStations.findIndex(st => st.cs_no === dialogState.station.cs_no);
                if (clickIndex >= 0) {
                    let newStationItem = {...newStations[clickIndex]}
                    newStationItem.is_spot = !newStationItem.is_spot;
                    newStations[clickIndex] = newStationItem;
                }

                setStations([...newStations])
            }
        } catch (err) {
            presentationError({
                error: err,
                type: 'handle my spot',
                alert: true
            });
        }
    }

    const getStationTip = async () => {
        try {
            const result = await getStationTips(dialogState.station.cs_no);

            if (result.success && result.data) {
                setDialogState(old => ({...old, tips: result.data}));
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'get tips',
                alert: true
            });
        }
    }

    const handleClickModifyTip = (cstNo: number, content: string) => {
        setDialogMode('modify');
        setSelTipNo(cstNo);
        setFormState({content: content});
        setShowDialog(true);
    }

    const handleClickDelTip = async (cstNo: number) => {
        try {
            const result = await deleteStationTip(dialogState.station.cs_no, cstNo);
            if (result.success) {
                const newTips = dialogState.tips.filter(tip => tip.cst_no !== cstNo);
                setDialogState(old => ({...old, tips: newTips}));
            }
        } catch (err) {
            presentationError({
                error: err,
                type: 'delete tip',
                alert: true
            });
        }
    }


    return (
        <div className={'dialog_wrapper'}>
            <Modal show={dialogState.isOpen} onHide={handleClose} className={'station_info_dialog_wrapper'}>
                <Modal.Header closeButton>
                    <Modal.Title>Station</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className={'info'}>
                            <div className={'sm_title'}>
                                <img src={chargerIcon} alt={'title_icon'}/>
                                <p>Info</p>
                            </div>
                            <div className={'flex_row_between align_content'}>
                                <div>
                                    <h5>{dialogState.station.cs_name}</h5>
                                    <p className={'cs_addr'}>{dialogState.station.cs_address}</p>
                                </div>
                                <button onClick={handleClickMySpot} id={'my_spot_btn'}>
                                    <img src={dialogState.station.is_spot ? fillHeartIcon : emptyHeartIcon}
                                         alt={'my_spot_icon'}/>
                                </button>
                            </div>
                        </div>
                        <hr/>
                        <div className={'price'}>
                            <div className={'sm_title'}>
                                <img src={dollarIcon} alt={'title_icon'}/>
                                <p>Price</p>
                            </div>
                            <div className={'align_content'}>
                                <span>(set by BroadStreet Properties)</span>
                                <span>
                                    <strong>
                                        $ {dialogState.station.cs_price}
                                    </strong>
                                    </span>
                            </div>
                        </div>
                        <hr/>
                        <div className={'tip'}>
                            <div className={'sm_title'}>
                                <img src={ideaIcon} alt={'title_icon'}/>
                                <p>Tips</p>
                            </div>
                            <div className={'align_content'}>
                                <EditTipDialog mode={dialogMode}
                                               show={showDialog}
                                               setShow={setShowDialog}
                                               tipNo={selTipNo}/>
                                <ul className={'tip_list'}>
                                    {dialogState.tips.map(tip => <li key={tip.cst_no} className={'tip_item'}>
                                        <div className={'flex_row_between w-100'}>
                                            <p className={'name'}>
                                                {`${tip.last_name} ${tip.first_name}`}
                                            </p>
                                            <div className={'comment_function'}>
                                                <button
                                                    onClick={() => handleClickModifyTip(tip.cst_no, tip.cst_content)}>Edit
                                                </button>
                                                <button
                                                    onClick={() => handleClickDelTip(tip.cst_no)}>Delete
                                                </button>
                                            </div>
                                        </div>
                                        <p className={'content'}>{tip.cst_content}</p>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default StationInfoDialog;