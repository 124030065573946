import {CreateCardRequest} from "model/request/CreateCardRequest";
import {UpdateCardResponse} from "../model/response/UpdateCardResponse";
import {AuthorizeHttpClient} from "../api/authorize-http-client-decorator";
import {API_ADDRESS} from "../api/ApiFactory";
import {HttpStatusCode} from "../api/http-client";
import {UnexpectedError} from "../model/errors/unexpected-error";
import {ApiError} from "../model/errors/ApiError";
import {IBaseResponse} from "../model/response/base-response-model";
import {PointCardListResponse, PointCardResponse} from "../model/response/PointCardResponse";
import {PointCardRequest, RegisterPointCardRequest} from "../model/request/PointCardRequest";
import {BasicResponse} from "../model/response/BasicResponse";

export const createCard = async (request: CreateCardRequest): Promise<UpdateCardResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/payment/card',
        method: 'post',
        body: request
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.created: return httpResponse.body as UpdateCardResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.conflict: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}

export const getCard = async (): Promise<UpdateCardResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/payment/card',
        method: 'get',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as UpdateCardResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.conflict: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}

export const getPointCard = async (): Promise<PointCardListResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/payment/pointcard',
        method: 'get',
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as PointCardListResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.conflict: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}

export const requestPointCard = async (request: PointCardRequest): Promise<PointCardListResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/payment/pointcard',
        method: 'post',
        body: request
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.created: return httpResponse.body as PointCardListResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.conflict: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}

export const registerPointCard = async (request: RegisterPointCardRequest): Promise<PointCardResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/payment/pointcard' ,
        method: 'patch',
        body: request
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as PointCardResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.conflict: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}

export const deletePointCard = async (cardNo: number): Promise<BasicResponse> => {
    const client = await new AuthorizeHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/payment/pointcard/' + cardNo,
        method: 'delete'
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: return httpResponse.body as BasicResponse
        case HttpStatusCode.badRequest: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.conflict: throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default: throw new UnexpectedError()
    }
}
