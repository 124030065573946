import React from 'react';
import {useRecoilState} from 'recoil';
import SelectBase, {ISelectItem} from "../../../common/select/SelectBase";
import {createPointCardFormState} from "../../atoms";


type Props = {
    name: string
    options: ISelectItem[]
    label: string
    valueLabel?: string
};

const Select: React.FC<Props> = ({name, options, label, valueLabel}: Props) => {
    const [state, setState] = useRecoilState(createPointCardFormState);

    return (
        <div className={'form_group'}>
            <label>{label}</label>
            <SelectBase
                valueLabel={valueLabel}
                className="select_base"
                name={name}
                state={state}
                setState={setState}
                options={options}/>
        </div>
    );
};

export default Select;
