import React from "react";


const DriverReportPage: React.FC = () => {


    return (
        <div className={'driver_report_page'}>

        </div>
    )
}


export default DriverReportPage;