import React, {useEffect, useState} from "react";
import InfoSection from "./InfoSection";
import CheckBox from "./form/CheckBox";
import pencilIcon from "../../assets/icon/pencil.svg";
import {presentationError} from "../../errors/presentationError";
import {getUser, updateUser} from "../../repository/UserRepository";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    createCardFormState,
    myAccountDetailState,
    profileCreditCardState,
    userProfileFormInit,
    userProfileFormState
} from "./atoms";
import {UpdateUserRequest} from "../../model/request/UpdateUserRequest";
import {dataToForm} from "../../util/common";
import closeIcon from "../../assets/icon/close.svg";
import checkIcon from "../../assets/icon/check.svg";
import {getCard} from "../../repository/PaymentRepository";


const MyAccountDetailPage: React.FC = () => {


    const [user, setUser] = useRecoilState(myAccountDetailState);
    const [userProfileForm, setUserProfileForm] = useRecoilState(userProfileFormState);
    const [notiModifyMode, setNotiModifyMode] = useState<boolean>(false);
    const [userProfileDetail, setUserProfileDetail] = useRecoilState(myAccountDetailState);
    const [profileCard, setProfileCard] = useRecoilState(profileCreditCardState);
    const setPcForm = useSetRecoilState(createCardFormState);
    useEffect(() => {
        getMyAccountDetail();
        getCreditCardInfo();
    }, [])

    const getMyAccountDetail = async () => {
        try {
            const result = await getUser();

            if (result.success && result.data) {
                setUser(result.data);
                setUserProfileForm(dataToForm(userProfileFormInit, result.data, userProfileForm) as UpdateUserRequest);
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'get account info',
                alert: true
            });
        }
    }

    const getCreditCardInfo = async () => {
        try {
            const result = await getCard();

            if (result.success && result.data) {
                const cardInfo = result.data
                setProfileCard(cardInfo);
                setPcForm({
                    number: cardInfo.pc_number,
                    validity: cardInfo.pc_validity,
                    cvc: cardInfo.pc_cvc,
                    zip_code: cardInfo.pc_zip_code
                });
            }

        } catch (err) {
            presentationError({
                error: err,
                type: 'get credit card info',
                alert: true
            });
        }
    }


    const modifyProfile = async () => {
        try {
            const result = await updateUser(userProfileForm);

            if (result.success) {
                setNotiModifyMode(false);
                setUserProfileDetail(Object.assign({}, userProfileDetail, userProfileForm));
            }
        } catch (err) {
            presentationError({
                error: err,
                type: 'modify user profile',
                alert: true
            });
        }
    }


    return (
        <div className={'my_account_detail_page page_container'}>
            <h3 id={'account_page_title'}>Account Details</h3>
            <div className={'login_details_wrapper'}>
                <div className={'profile_car'}>
                    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M2.70216 5.21397L3.96348 0.729255C4.08478 0.297975 4.47812 0 4.92613 0H17.1977C17.6457 0 18.039 0.297975 18.1603 0.729254L19.4217 5.21394C19.5069 5.14647 19.6146 5.10618 19.7318 5.10618H21.6234C21.9584 5.10618 22.1986 5.42905 22.1024 5.74985L21.7896 6.79232C21.7262 7.00381 21.5315 7.14865 21.3107 7.14865H19.9333C19.9341 7.18561 19.9329 7.22227 19.9297 7.2585C20.7123 7.52989 21.2742 8.27362 21.2742 9.14859V15.3609C21.2742 16.1099 20.8625 16.7628 20.253 17.1055V18.4034C20.253 18.9557 19.8053 19.4034 19.253 19.4034H18.1893C17.637 19.4034 17.1893 18.9557 17.1893 18.4034V17.3609H4.93451V18.4034C4.93451 18.9557 4.48679 19.4034 3.93451 19.4034H2.87081C2.31852 19.4034 1.87081 18.9557 1.87081 18.4034V17.1055C1.26129 16.7628 0.849523 16.11 0.849523 15.3609V9.14859C0.849523 8.27358 1.41143 7.52982 2.19408 7.25846C2.19088 7.22225 2.18967 7.1856 2.19053 7.14865H0.813068C0.592264 7.14865 0.397602 7.00381 0.334155 6.79232L0.0214147 5.74985C-0.0748271 5.42905 0.165397 5.10618 0.500328 5.10618H2.39201C2.50917 5.10618 2.61692 5.14648 2.70216 5.21397ZM5.25578 1.77867C5.36707 1.3335 5.76706 1.0212 6.22592 1.0212H15.8979C16.3568 1.0212 16.7568 1.3335 16.8681 1.77867L18.2106 7.1486H3.9133L5.25578 1.77867ZM5.27169 12.2549H16.8522C17.5263 12.2549 18.0073 12.9082 17.807 13.5519L17.4761 14.6156C17.346 15.0337 16.9591 15.3186 16.5212 15.3186H5.60261C5.16476 15.3186 4.77783 15.0337 4.64776 14.6156L4.31683 13.5519C4.11657 12.9082 4.59758 12.2549 5.27169 12.2549ZM4.93451 9.70172C4.93451 10.5477 4.24868 11.2336 3.40266 11.2336C2.55664 11.2336 1.87081 10.5477 1.87081 9.70172C1.87081 8.85571 2.55664 8.16987 3.40266 8.16987C4.24868 8.16987 4.93451 8.85571 4.93451 9.70172ZM18.7211 11.2336C19.5671 11.2336 20.253 10.5477 20.253 9.70172C20.253 8.85571 19.5671 8.16987 18.7211 8.16987C17.8751 8.16987 17.1893 8.85571 17.1893 9.70172C17.1893 10.5477 17.8751 11.2336 18.7211 11.2336Z"
                              fill="#BC2555"/>
                    </svg>
                    <h5>{user.username}</h5>
                    <span>EVatar Name</span>
                </div>
                <InfoSection title={'Login Details'} infos={[
                    {
                        name: 'Username',
                        value: user.username,
                        formName: 'username',
                    },
                    {
                        name: 'Account Number',
                        value: user.account_number

                    },
                    {
                        name: 'Password',
                        value: '*****'
                    }
                ]}/>
            </div>
            <hr/>
            <InfoSection title={'Profile'} infos={[
                {
                    name: 'First Name',
                    value: user.first_name,
                    formName: 'first_name',
                },
                {
                    name: 'Last Name',
                    value: user.last_name,
                    formName: 'last_name',
                },
                {
                    name: 'Email',
                    value: user.email,
                    formName: 'email',
                },
                {
                    name: 'Mobile',
                    value: `+${user.mobile_code}-${user.mobile_number}`
                },
                {
                    name: 'Preferred Language',
                    value: user.language
                }
            ]}/>
            <InfoSection title={'Contact Address'} infos={[
                {
                    name: 'Address Line 1',
                    value: user.address_line_1,
                    formName: 'address_line_1',
                },
                {
                    name: 'Address Line 2',
                    value: user.address_line_2,
                    formName: 'address_line_2',
                },
                {
                    name: 'City',
                    value: user.address_city,
                    formName: 'address_city',
                },
                {
                    name: 'State',
                    value: user.address_state,
                    formName: 'address_state',
                },
                {
                    name: 'Zip/Postal Code',
                    value: user.address_zip_code,
                    formName: 'address_zip_code',
                },
                {
                    name: 'Country',
                    value: user.address_country,
                    formName: 'address_country',
                }
            ]}/>
            <InfoSection title={'Shipping Address'} infos={[
                {
                    name: 'Address Line 1',
                    value: user.shipping_address_line_1,
                    formName: 'shipping_address_line_1',
                },
                {
                    name: 'Address Line 2',
                    value: user.shipping_address_line_2,
                    formName: 'shipping_address_line_2',
                },
                {
                    name: 'City',
                    value: user.shipping_address_city,
                    formName: 'shipping_address_city',
                },
                {
                    name: 'State',
                    value: user.shipping_address_state,
                    formName: 'shipping_address_state',
                },
                {
                    name: 'Zip/Postal Code',
                    value: user.shipping_address_zip_code,
                    formName: 'shipping_address_zip_code',
                },
                {
                    name: 'Country',
                    value: user.shipping_address_country,
                    formName: 'shipping_address_country',
                }
            ]}/>
            <hr/>
            <InfoSection title={'Financial Information'} infos={[
                {
                    name: 'Balance',
                    value: '$ 11.84'
                },
                {
                    name: 'Currency',
                    value: 'U.S.Dollars (USD)'
                },
                {
                    name: 'Payment Source',
                    value: `Credit Card(... ${profileCard.pc_number.slice(-4)})`
                }
            ]}/>
            <hr/>
            <div className={'notification_setting'}>
                <div className={'section_header'}>
                    <h5>Your ChargePoint Notifications</h5>
                    {notiModifyMode ?
                        <div className={'d-flex'}>
                            <button onClick={() => setNotiModifyMode(false)}>
                                <img src={closeIcon} alt={'modify_icon'}/>
                            </button>
                            <button onClick={modifyProfile}>
                                <img src={checkIcon} alt={'modify_icon'}/>
                            </button>
                        </div>
                        :
                        <button onClick={() => setNotiModifyMode(true)}>
                            <img src={pencilIcon} alt={'modify_icon'}/>
                        </button>
                    }
                </div>
                <div className={'w-50 setting_section'}>
                    <h4>Public Charger</h4>
                    <div className={'flex_row_between notification_info'}>
                        Fully Charged
                        <CheckBox disabled={!notiModifyMode} name={'noti_fully'} label={''}/>
                    </div>
                    <div className={'flex_row_between notification_info'}>
                        Charging Interrupted
                        <CheckBox disabled={!notiModifyMode} name={'noti_charging'} label={''}/>
                    </div>
                    <div className={'flex_row_between notification_info'}>
                        Receipt
                        <CheckBox disabled={!notiModifyMode} name={'noti_receipt'} label={''}/>
                    </div>
                </div>
                <div className={'w-50 setting_section'}>
                    <h4>Notify Me By</h4>
                    <div className={'flex_row_between notification_info'}>
                        Email
                        <CheckBox disabled={!notiModifyMode} name={'noti_email'} label={''}/>
                    </div>
                    <div className={'flex_row_between notification_info'}>
                        Text
                        <CheckBox disabled={!notiModifyMode} name={'noti_text'} label={''}/>
                    </div>
                </div>
                <div className={'info_section_wrapper noti_info'}>
                    <div className={'info'}>
                        <p className={'info_value'}>{user.noti_phone_code + user.noti_phone_number}</p>
                        <small>Text Notification Number</small>
                    </div>
                    <div className={'info'}>
                        <p className={'info_value'}>{user.noti_email_address}</p>
                        <small>Email Notification Address</small>
                    </div>
                </div>
            </div>
            <hr/>
            <div className={'account_promotion'}>
                <h5>Promotion Code</h5>
                <label>
                    Promotion Code(Optional)
                    <div>
                        <input type={'text'}/>
                        <button>Apply</button>
                    </div>
                </label>
            </div>
        </div>
    )
}


export default MyAccountDetailPage;