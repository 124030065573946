import axios from "axios";
import {RegisterRequest} from "../model/request/RegisterRequest";
import {BasicResponse} from "../model/response/BasicResponse";
import {HttpClient, HttpStatusCode} from "../api/http-client";
import {AxiosHttpClient} from "../api/axios-http-client";
import {API_ADDRESS} from "../api/ApiFactory";
import {ApiError} from "model/errors/ApiError";
import {UnexpectedError} from "../model/errors/unexpected-error";
import {IBaseResponse} from "model/response/base-response-model";
import {LoginRequest} from "../model/request/loginRequest";
import {LoginResponse} from "../model/response/LoginResponse";
import {LocalStorageAdapter} from "../api/local-storage-adapter";

export const login = async (request: LoginRequest): Promise<LoginResponse> => {
    const client = await new AxiosHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/auth/local',
        method: 'post',
        body: request
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: {
            const res = httpResponse.body as LoginResponse;
            const storage = new LocalStorageAdapter()
            storage.set('token', {access: res.data.token})
            return res
        }
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default:
            throw new UnexpectedError()
    }
}

export const logout = async (): Promise<BasicResponse> => {
    const client = await new AxiosHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/auth/local',
        method: 'delete'
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.ok: {
            const storage = new LocalStorageAdapter()
            const token = storage.get('token')
            if (token) {
                if (token.access) {
                    await localStorage.removeItem('token');
                }
            }
            return httpResponse.body as BasicResponse
        }
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default:
            throw new UnexpectedError()
    }

}


export const register = async (request: RegisterRequest): Promise<BasicResponse> => {
    const client = await new AxiosHttpClient();
    const httpResponse = await client.request({
        url: API_ADDRESS + '/auth/local/new',
        method: 'post',
        body: request
    })
    switch (httpResponse.statusCode) {
        case HttpStatusCode.created:
            return httpResponse.body as BasicResponse
        case HttpStatusCode.badRequest:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.unauthorized:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.forbidden:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        case HttpStatusCode.notFound:
            throw new ApiError((httpResponse.body as IBaseResponse).code_error, (httpResponse.body as IBaseResponse).message)
        default:
            throw new UnexpectedError()
    }
}
