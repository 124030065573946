import React, {useState, KeyboardEvent} from 'react';
import Input from './Input';
import {useRecoilValue} from "recoil";
import mainLogo from '../../../assets/image/brand_logo.png';

import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {loginFormState} from "./atoms";
import {presentationError} from "../../../errors/presentationError";
import LoaderButton from "../../common/button/LoaderButton";
import {login} from "../../../repository/AuthRepository";

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const loginForm = useRecoilValue(loginFormState);
    const [loader, setLoader] = useState<boolean>(false);
    const handleLogin = async () => {
        try {
            setLoader(true);
            const result = await login(loginForm)

            if (result.success) {
                setLoader(false);
                window.location.reload();
            }
        } catch (err) {
            presentationError({
                error: err,
                type: '로그인',
                alert: true
            });
            setLoader(false);
        }
    }

    const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className="login_page_wrapper">
            <div className="login_box">
                <div className="main_logo">
                    <img src={mainLogo} alt={'main_logo'}/>
                </div>
                <h2 style={{marginBottom: '1rem'}}>Login</h2>
                <Input onKeyPress={handleOnKeyPress} maxLength={50} type="text" name="email" placeholder="Enter email"/>
                <Input onKeyPress={handleOnKeyPress} maxLength={255} type="password" name="password"
                       placeholder="Enter password"/>
                <LoaderButton onClick={handleLogin} loader={loader} type="button">Login</LoaderButton>
                <div className={'to_register'}>
                    <p>Don’t have a driver account?
                        <Link to={'register'}>
                            Sign up
                        </Link></p>
                </div>

            </div>
        </div>
    );
}

export default LoginPage;
