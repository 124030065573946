import React from "react";
import {Link, useNavigate} from "react-router-dom";


const AccountActivityTable: React.FC = () => {

    const navigator = useNavigate();

    return (
        <div className={'account_activity_table_wrapper'}>
            <div className={'flex_row_between'}>
                <h3 className={'section_title'}>
                    Current Account Activity
                </h3>

                <div className={'btns_wrapper'}>
                    <button onClick={() => navigator('/report')}>View Reports</button>
                    <button>Manage My Account</button>
                </div>
            </div>
            <table>
                <colgroup>
                    <col width={"30%"}/>
                    <col width={"25%"}/>
                    <col width={"20%"}/>
                    <col width={"10%"}/>
                    <col width={"15%"}/>
                </colgroup>
                <thead>
                <tr>
                    <th>Account Number</th>
                    <th>Statement Period</th>
                    <th>Total Charging Duration</th>
                    <th>Usage Charges</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>DNACLB364B31FAB428F</td>
                    <td>01-Jan-2023 To 31-Jan-2023</td>
                    <td>00:00:00 (HH:MM:SS)</td>
                    <td><strong>$0.00</strong></td>
                    <td>
                        <Link to={'/'}>
                            View Statement Details
                        </Link>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}


export default AccountActivityTable;