import {atom} from 'recoil'
import {RegisterPointCardRequest} from "../../model/request/PointCardRequest";

interface IUserInfo {
    first_name: string
    last_name: string
    step1: boolean
    step2: boolean
    step3: boolean
    step4: boolean
    step5: boolean
}

export const userInfoState = atom<IUserInfo>({
    key: 'userNameInfoState',
    default: {
        first_name: '',
        last_name: '',
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
    }
})
