import React from "react";
import Form from 'react-bootstrap/Form';

const AccountStatementPage: React.FC = () => {


    return (
        <div className={'account_statement_page page_container'}>
            <h3 id={'account_page_title'}>Account Statement</h3>
            <div className={'select_date'}>
                <h2>View Monthly Statement for</h2>
                <div className={'date_form'}>
                    <label id={'month_label'}>Month</label>
                    <Form.Select>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </Form.Select>
                    <label id={'year_label'}>Year</label>
                    <Form.Select>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                    </Form.Select>
                </div>
            </div>
            <hr/>
            <div className={'user_info flex_row_between'}>
                <div className={'left_section'}>
                    <span>Jibong Jang</span>
                    <span>647 Cultivate,</span>
                    <span>Irvine, California 92618,</span>
                    <span>United States</span>
                </div>
                <div className={'right_section'}>
                    <span>Account #: DNACLB36434WE232</span>
                    <span className={'up_margin'}>Nov 1, 2022 to Nov 30, 2022</span>
                </div>
            </div>
            <div className={'sub_container'}>
                <div className={'sub_section'}>
                    <h3 className={'sub_title'}>Payment Activity</h3>
                    <table className={'payment_activity_table'}>
                        <colgroup>
                            <col width="60%"/>
                            <col width="20%"/>
                            <col width="20%"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Transaction Date (PT)</th>
                            <th>Description</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>wejifow</td>
                            <td>wejifow</td>
                            <td>wejifow</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'sub_section'}>
                    <h3 className={'sub_title'}>Account Balance</h3>
                    <ul className={'payment_balance_list'}>
                        <li className={'flex_row_between'}>
                            <span>Account Balance on Nov 1, 2022</span>
                            <span><strong>$11.84</strong></span>
                        </li>
                        <li className={'flex_row_between'}>
                            <span>Account Funding and Other Credits</span>
                            <span>$0.00</span>
                        </li>
                        <li className={'flex_row_between'}>
                            <span>Session Fees</span>
                            <span>$0.00</span>
                        </li>
                        <li className={'flex_row_between'}>
                            <span>Currency Conversion Fees</span>
                            <span>$0.00</span>
                        </li>
                        <li className={'flex_row_between'}>
                            <span>Account balance rollover to Dec 1, 2022</span>
                            <span><strong>$11.84</strong></span>
                        </li>
                    </ul>
                </div>

                <div className={'sub_section'}>
                    <h3 className={'sub_title'}>Account Funding and Other Credits</h3>
                    <table className={'account_funding_table'}>
                        <colgroup>
                            <col width="60%"/>
                            <col width="20%"/>
                            <col width="20%"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Transaction Date (PT)</th>
                            <th>Description</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>wejifow</td>
                            <td>wejifow</td>
                            <td>wejifow</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}


export default AccountStatementPage;