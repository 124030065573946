import {atom} from 'recoil'
import {v1} from 'uuid'
import {Station} from "../../model/response/StationListResponse";
import {StationSpot} from "../../model/response/StationSpotListResponse";

interface IState {
    stationStatus: number
    stationConnType: number[]
}

export const sidebarState = atom<IState>({
    key: `sidebarState/${v1()}`,
    default: {
        stationStatus: 0,
        stationConnType: []
    }
})

export const stationsState = atom<Station[]>({
    key: `stationsState/${v1()}`,
    default: []
})


export const myStationsState = atom<StationSpot[]>({
    key: `myStationsState/${v1()}`,
    default: []
})
