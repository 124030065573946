import React from "react";
import carIcon from '../../assets/icon/profile_car.svg';
import {useRecoilState, useRecoilValue} from "recoil";
import {userInfoState} from "../nav/atoms";
import classNames from "classnames";

const UserProfileBar: React.FC = () => {

    const userInfo = useRecoilValue(userInfoState);

    return (
        <div className={'user_profile_bar_wrapper'}>
            <div className={'complete_icon'}>
                <img src={carIcon} alt={'car_icon'}/>
            </div>
            <div className={'profile_info'}>
                <h4>
                    Hello, {`${userInfo.last_name} ${userInfo.first_name}`}
                </h4>
                <p>
                    Your profile is complete. You can now take EVpoint.
                </p>
            </div>
            <div className={'profile_progress'}>
                <div className={'progress_indicator'}>
                    <div className={classNames({
                      'progress_ball': true,
                      'active' : userInfo.step1
                    })}/>
                    <div className={classNames({
                        'progress_ball': true,
                        'active' : userInfo.step2
                    })}/>
                    <div className={classNames({
                        'progress_ball': true,
                        'active' : userInfo.step3
                    })}/>
                    <div className={classNames({
                        'progress_ball': true,
                        'active' : userInfo.step4
                    })}/>
                    <div className={classNames({
                        'progress_ball': true,
                        'active' : userInfo.step5
                    })}/>
                </div>
                <small>Your Profile Progress</small>
            </div>
        </div>
    )
}


export default UserProfileBar;